<template>
  <div class="relative flex-shrink-0 flex h-16 bg-white">
    <div class="pt-1 pl-4 pr-4 sm:pl-6 sm:pr-6 flex-1 flex justify-between items-center">
      <button
        class="text-gray-700 focus:outline-none active:text-gray-400 hover:text-gray-500"
        aria-label="Toggle sidebar"
        @click="onToggleSidebar"
      >
        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </button>
      <div class="ml-4 flex items-center md:ml-6">
        <button
          class="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring focus:text-gray-500"
          aria-label="Notifications"
        >
          <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
        </button>

        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <NavbarTopProfileDropdown />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavbarTopProfileDropdown from "./NavbarTopProfileDropdown.vue";

export default defineComponent({
  name: "NavbarTop",
  components: { NavbarTopProfileDropdown },
  emits: ["togglesidebar"],
  setup(props, context) {
    // expand sidebar
    const onToggleSidebar = () => {
      context.emit("togglesidebar");
    };

    return { onToggleSidebar };
  }
});
</script>
