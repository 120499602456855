<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div
    id="base-notification"
    class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto"
  >
    <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <!-- Heroicon name: check-circle -->
            <svg
              v-if="notification.type == 'success'"
              class="h-6 w-6 text-green-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <!-- Heroicon name: exclamation -->
            <svg
              v-if="notification.type == 'error'"
              class="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm leading-5 font-medium" :class="[titleClasses]">
              {{ notification.title }}
            </p>
            <p class="mt-1 text-sm leading-5 text-gray-500">
              <slot> {{ notification?.body }}</slot>
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              @click="onRemoveNotification"
            >
              <!-- Heroicon name: x -->
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import useNotifications, { Notification } from "./useNotifications";

export default defineComponent({
  name: "BaseNotification",
  props: {
    notification: { type: Object as PropType<Notification>, required: true }
  },
  setup(props) {
    const { removeNotification } = useNotifications();

    const onRemoveNotification = () => {
      removeNotification(props.notification.id);
    };

    const titleClasses = computed(() => {
      return {
        info: "text-gray-900",
        success: "text-green-500",
        error: "text-red-700"
      }[props.notification.type as "info" | "success" | "error"];
    });

    return {
      onRemoveNotification,
      titleClasses
    };
  }
});
</script>
