
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseLabel",
  props: {
    text: {
      type: String,
      default: ""
    },
    forId: {
      type: String,
      default: "baseLabelPlaceholder"
    }
  }
});
