/**
 * Router authenticaiton handling
 */

import useAuth from "@/api/auth/useAuth";

// import session variable
const { session } = useAuth();

export default function addRouteProtection(router: any, routes: any) {
  // route protection
  // https://www.digitalocean.com/community/tutorials/how-to-set-up-vue-js-authentication-and-route-handling-using-vue-router
  router.beforeEach((to: any, from: any, next: any) => {
    // redirect user, if already authenticated and tries to access e.g. login
    if (to.meta.requiresAuth === false) {
      if (session.value) {
        // console.log(to);
        next({
          name: "home"
        });
      } else next();
    }
    // check auth protexted routes
    else if (to.matched.some((record: any) => record.meta.requiresAuth)) {
      // check if user is authenticated, if not redirect to login
      if (!session.value) {
        next({
          name: "login",
          params: { nextUrl: to.fullPath }
        });
      } else next();
      // if user is authenticated, check other roles
      // } else {
      //     let user = JSON.parse(localStorage.getItem('user'))
      //     if(to.matched.some(record => record.meta.is_admin)) {
      //         if(user.is_admin == 1){
      //             next()
      //         }
      //         else{
      //             next({ name: 'userboard'})
      //         }
      //     }else {
      //         next()
      //     }
      // }
      //
    }
  });
}
