<template>
  <div class="w-full">
    <div class="flex flex-1 justify-between mb-1">
      <label class="block text-sm font-medium text-gray-700" :for="name">
        <span v-if="label !== ''">{{ label }}</span>
        <slot name="label"></slot>
      </label>
      <div class="text-sm text-gray-500">
        <slot name="hint"></slot>
      </div>
    </div>
    <div :class="addonClasses" class="flex shadow-sm">
      <!-- Addon before input -->
      <span
        v-if="addon === 'left'"
        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
      >
        <slot name="addon-left"></slot>
      </span>
      <div class="relative inline-flex" :class="inputSizeClasses">
        <input
          class="block sm:text-sm"
          :class="[stateClasses, inputSizeClasses, addonClasses, alignTextClasses]"
          :id="name"
          :name="name"
          :type="type"
          :autocomplete="autocomplete"
          v-bind="$attrs"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          ref="textInput"
        />
        <!-- Error Icon -->
        <div v-show="displayError" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
      <!-- Addon after input -->
      <div
        v-if="addon === 'right'"
        :class="[inputSize === 'auto' ? '' : 'w-full']"
        class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
      >
        <slot name="addon-right"></slot>
      </div>
    </div>
    <p class="mt-2 text-sm text-red-600" v-show="displayError">
      {{ validationError }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, PropType, computed } from "vue";
import { StringSchema, ValidationError } from "yup";

// modelValue: usage of v-model in custom components based on https://v3.vuejs.org/guide/component-basics.html#using-v-model-on-components
export default defineComponent({
  name: "BaseInput",
  props: {
    autocomplete: {
      type: String,
      default: "off"
    },
    focus: {
      type: Boolean,
      default: false
    },
    focusDelay: {
      type: String,
      default: "large",
      validator: (prop: string) => ["small", "large"].includes(prop)
    },
    label: {
      type: String,
      default: ""
    },
    // only used for v-model binding, no actual prop
    modelValue: {
      type: [String, Number, Date],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "text"
    },
    inputSize: {
      type: String,
      validator: (prop: string) => ["sm", "md", "lg", "auto"].includes(prop),
      default: "auto"
    },
    alignText: {
      type: String,
      validator: (prop: string) => ["left", "right", "center"].includes(prop),
      default: "left"
    },
    addon: {
      type: String,
      validator: (prop: string) => ["left", "right", "none"].includes(prop),
      default: "none"
    },
    validate: {
      type: Boolean,
      default: false
    },
    schema: { type: (Object as PropType<StringSchema>) || null, default: null }
  },
  setup(props) {
    /**
     * Validation
     */

    // validation error
    const validationError = ref<string | null>(null);
    // only show error if validation is active
    const displayError = computed(() => {
      return props.validate === true && validationError.value;
    });

    // validate input
    const validateInput = () => {
      props.schema
        .validate(props.modelValue)
        .then(() => {
          validationError.value = null;
        })
        .catch((err: ValidationError) => {
          validationError.value = err.errors[0];
        });
    };

    // watch for input changes to validate
    if (props.schema)
      watch(
        () => props.modelValue,
        () => {
          validateInput();
        }
      );

    // watch for validation variable changes (usually after click submit)
    // acticate / deactivate validation
    if (props.schema)
      watch(
        () => props.validate,
        validate => {
          if (validate) {
            validateInput();
          }
        }
      );

    /**
     * Styles
     */

    // color classes
    const defaultStateClasses = "focus:ring-blue-500 focus:border-blue-500 border-gray-300";
    const errorStateClasses =
      "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";
    const stateClasses = computed(() => {
      return displayError.value ? errorStateClasses : defaultStateClasses;
    });

    // focus delay
    const focusDelayTimes = computed(() => {
      return {
        small: 100,
        large: 500
      }[props.focusDelay as "small" | "large"];
    });

    // small input field for short inputs
    const inputSizeClasses = computed(() => {
      return {
        sm: displayError.value ? "w-20" : "w-12", // 2 chars
        md: displayError.value ? "w-28" : "w-20", // 6 chars
        lg: displayError.value ? "w-40" : "w-32", // 11 chars
        auto: "w-full"
      }[props.inputSize as "sm" | "md" | "lg" | "auto"];
    });

    // define the shape of the input field based on potential addons
    const addonClasses = computed(() => {
      return {
        left: "rounded-r-md",
        right: "rounded-l-md",
        none: "rounded-md"
      }[props.addon as "left" | "right" | "none"];
    });

    // align input text
    const alignTextClasses = computed(() => {
      return {
        left: "text-left",
        right: "text-right",
        center: "text-center"
      }[props.alignText as "left" | "right" | "center"];
    });

    // focus after component was created
    const textInput = ref<HTMLElement | null>(null);
    onMounted(() => {
      if (props.focus === true) {
        // Option 1: Does not open keyboard on safari
        // without timeout, the focus function causes the slide panel to push the router content
        setTimeout(() => {
          textInput.value?.focus();
        }, focusDelayTimes.value);
        // console.log(textInput.value);
        // // Option 2: Breaks page on safari
        // textInput.value?.focus({ preventScroll: true });
      }
    });

    return {
      inputSizeClasses,
      stateClasses,
      addonClasses,
      alignTextClasses,
      displayError,
      validationError,
      textInput
    };
  }
});
</script>
