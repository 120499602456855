<template>
  <router-view v-if="!session" name="auth" />
  <div v-else class="h-screen flex bg-white overflow-hidden relative">
    <NavbarSide :visible="sidebarVisible" />
    <!-- Outlet for modal -->
    <div id="modal"></div>
    <div class="flex flex-col flex-1 overflow-hidden h-full relative">
      <NavbarTop @togglesidebar="onToggleNavbarSide" />
      <router-view />
      <!-- Outlet for slideover -->
      <div id="slideover"></div>
      <!-- Outlet for notificatios -->
      <div class="absolute inset-0 flex pointer-events-none">
        <NotificationList />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import NavbarTop from "./components/navbar/NavbarTop.vue";
import NavbarSide from "./components/navbar/NavbarSide.vue";
import NotificationList from "@/components/notifications/NotificationList.vue";
import useAuth from "@/api/auth/useAuth";

export default defineComponent({
  name: "App",
  components: {
    NavbarTop,
    NavbarSide,
    NotificationList
  },
  setup() {
    const sidebarVisible = ref(false);

    const onToggleNavbarSide = () => {
      sidebarVisible.value = !sidebarVisible.value;
    };

    // session variable
    const { session } = useAuth();

    return {
      sidebarVisible,
      onToggleNavbarSide,
      session
    };
  }
});
</script>

<style src="./css/tailwind.css" />
