import { reactive } from "vue";

// Type
interface TimeoutsState {
  timeouts: Timeout[];
}

export interface Timeout {
  id: NodeJS.Timeout;
  targetId: string;
}

// Manage a list of timeouts
// Timeouts execute a callback after a predefined time
// Timeouts can be removed before execution
export default function useTimeouts() {
  // Initialize notification state
  const state: TimeoutsState = reactive({
    timeouts: []
  });

  // add timeout
  const addTimeout = (callback: Function, targetId: string, time: number) => {
    // console.log("add timeout" + targetId)
    const timeoutId = setTimeout(() => {
      callback(targetId);
    }, time);
    // add timeout to state
    state.timeouts = [...state.timeouts, { id: timeoutId, targetId: targetId }];
  };

  // remove timeout
  const removeTimeout = (targetId: string) => {
    // console.log("Remove timeout for notification " + targetId)
    // remove actual timeout
    const timeoutList = state.timeouts.filter(
      timeout => timeout.targetId == targetId
    );
    timeoutList.forEach(t => clearTimeout(t.id));
    // remove timeout from state list
    state.timeouts = [
      ...state.timeouts.filter(timeout => timeout.targetId != targetId)
    ];
  };

  return {
    addTimeout,
    removeTimeout
  };
}
