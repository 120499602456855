
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "NavbarSideLink",
  props: {
    // eslint-disable-next-line
    link: {
      type: String,
      required: true
    },
    showText: {
      default: true,
      type: Boolean
    },
    routeNameList: {
      type: Array
    },
    routePage: {
      type: String
    }
  },
  setup(props) {
    const route = useRoute();

    // Create status for css classes
    // Links if active, if matches either routeNameList or routePage
    const linkStatus = computed(() => {
      if (props.routePage != undefined) {
        if (route.meta.page == props.routePage) {
          return "active";
        } else {
          return "inactive";
        }
      } else if (props.routeNameList != undefined) {
        if (props.routeNameList.includes(route.name)) {
          return "active";
        } else {
          return "inactive";
        }
      }
    });

    // CSS classes for icon
    const iconClasses = computed(() => {
      return {
        active: "text-gray-600  group-focus:text-gray-700",
        inactive: "text-gray-500  group-focus:text-gray-600"
      }[linkStatus.value as "active" | "inactive"];
    });
    // CSS classes for text
    const linkClasses = computed(() => {
      return {
        active: "text-gray-900 bg-gray-100 hover:text-gray-900 hover:bg-gray-100 active:bg-gray-200",
        inactive: "text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:text-gray-900 focus:bg-gray-50"
      }[linkStatus.value as "active" | "inactive"];
    });

    return { iconClasses, linkClasses };
  }
});
