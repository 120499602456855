import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

import link from "./apollo/links";
import cache from "./apollo/cache";

export const defaultClient = new ApolloClient<NormalizedCacheObject>({
  link: link,
  cache: cache,
  connectToDevTools: process.env.NODE_ENV === "development"
});
