import { computed, reactive, readonly } from "vue";
import { Auth } from "@aws-amplify/auth";
import router from "@/router";

// Session model
export interface Session {
  userId: string;
  authId: string;
  tenantId: string;
  userRole: string;
  allowedRoles: string[];
}

// Session state model
export interface AuthState {
  session: Session | null;
}

// Initialize session state
const state: AuthState = reactive({
  session: null
});

// convert cognito idToken to session object
const createSessionFromCognitoData = (idToken: any): Session => {
  // get custom hasura data
  const hasuraClaims = JSON.parse(
    idToken.payload["https://hasura.io/jwt/claims"]
  );
  // create session object
  const session: Session = {
    userId: hasuraClaims["x-hasura-user-id"],
    authId: hasuraClaims["x-hasura-auth-id"],
    tenantId: hasuraClaims["x-hasura-tenant-id"],
    userRole: hasuraClaims["x-hasura-default-role"],
    allowedRoles: hasuraClaims["x-hasura-allowed-roles"]
  };

  return session;
};

// Session state hook
export default function useAuth() {
  // initialize session based on cognito api response
  const initializeSession = (idToken: any) => {
    state.session = createSessionFromCognitoData(idToken);
  };

  // get session variable before loading the app
  const checkSessonVariable = () => {
    // add session variable in dev mode
    if (
      process.env.NODE_ENV === "development" &&
      process.env.VUE_APP_DEBUG_PROD_API !== "true"
    ) {
      state.session = {
        userId: "e8d1c0d1-9d34-40a6-833a-209b5a74184c",
        authId: "ec406b09-257b-438d-8640-ef55fcd37bd7",
        tenantId: "dfcf8500-9aa0-4f16-b589-dd3ff35c78ef",
        userRole: "tenant_admin",
        allowedRoles: ["employee", "planner", "tenant_admin"]
      };
    } else {
      // check if user is currently signed in
      Auth.currentSession()
        .then(sessionData => {
          initializeSession(sessionData.getIdToken());
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  // delete session
  const deleteSession = () => {
    state.session = null;
    router.push({ name: "login" });
  };

  return {
    session: computed(() => state.session),
    tenantId: computed(() => state.session?.tenantId),
    initializeSession,
    checkSessonVariable,
    deleteSession
  };
}
