
import { defineComponent, computed, PropType } from "vue";
import useNotifications, { Notification } from "./useNotifications";

export default defineComponent({
  name: "BaseNotification",
  props: {
    notification: { type: Object as PropType<Notification>, required: true }
  },
  setup(props) {
    const { removeNotification } = useNotifications();

    const onRemoveNotification = () => {
      removeNotification(props.notification.id);
    };

    const titleClasses = computed(() => {
      return {
        info: "text-gray-900",
        success: "text-green-500",
        error: "text-red-700"
      }[props.notification.type as "info" | "success" | "error"];
    });

    return {
      onRemoveNotification,
      titleClasses
    };
  }
});
