import { RouteRecordRaw } from "vue-router";

const scheduleRoutes: Array<RouteRecordRaw> = [
  {
    path: "/schedules",
    name: "schedules",
    component: () => import(/* webpackChunkName: "schedules" */ "@/pages/SchedulesPage.vue"),
    meta: {
      requiresAuth: true,
      page: "schedules"
    }
  },
  {
    path: "/schedules/new",
    name: "scheduleFormPage",
    component: () => import(/* webpackChunkName: "scheduleFormPage" */ "@/pages/ScheduleFormPage.vue"),
    meta: {
      requiresAuth: true,
      page: "schedules"
    }
  },
  {
    path: "/schedules/:scheduleId",
    name: "scheduleDetails",
    // redirect: "/schedules/:scheduleId/settings",
    component: () => import(/* webpackChunkName: "scheduleDetails" */ "@/pages/SchedulePlanningPage.vue"),
    // children: [
    //   {
    //     path: "settings",
    //     name: "scheduleSettings",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "scheduleSettings" */ "@/features/schedules/details/ScheduleDetailsPageSettings.vue"
    //       )
    //   },
    //   {
    //     path: "employees",
    //     name: "scheduleEmployees",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "scheduleEmployees" */ "@/features/schedules/details/ScheduleDetailsPageEmployees.vue"
    //       )
    //   },
    //   {
    //     path: "demand",
    //     name: "scheduleDemand",
    //     component: () =>
    //       import(/* webpackChunkName: "scheduleDemand" */ "@/features/schedules/details/ScheduleDetailsPageDemand.vue")
    //   },
    //   {
    //     path: "demand/:demandTemplateId",
    //     name: "demandTemplateDetails",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "demandTemplateDetails" */ "@/features/demandTemplates/details/DemandTemplateDetailsPage.vue"
    //       )
    //   }
    // ],
    meta: {
      requiresAuth: true,
      page: "schedules"
    }
  }
];

export default scheduleRoutes;
