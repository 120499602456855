
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { defineComponent } from "vue";
// auth
import { Auth } from "@aws-amplify/auth";
import useAuth from "@/api/auth/useAuth";

export default defineComponent({
  name: "NavbarTopProfileDropdown",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  },
  setup() {
    // session variable
    const { deleteSession } = useAuth();
    // logout
    const onLogout = () => {
      Auth.signOut()
        .then(() => {
          deleteSession();
        })
        .catch(e => {
          console.error(e);
        });
    };

    return { onLogout };
  }
});
