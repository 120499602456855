import { RouteRecordRaw } from "vue-router";

const workAreaRoutes: Array<RouteRecordRaw> = [
  {
    path: "/work-areas",
    name: "workAreas",
    component: () => import(/* webpackChunkName: "workAreas" */ "@/pages/WorkAreasPage.vue"),
    meta: {
      requiresAuth: true,
      page: "workAreas"
    }
  },

  {
    path: "/work-areas/:workAreaId",
    name: "workAreaDetails",
    redirect: "/work-areas/:workAreaId/settings",
    component: () => import(/* webpackChunkName: "workAreaDetails" */ "@/pages/WorkAreaDetailsPage.vue"),
    children: [
      {
        path: "settings",
        name: "workAreaSettings",
        meta: { page: "workAreas" },
        component: () =>
          import(
            /* webpackChunkName: "workAreaSettings" */ "@/features/workAreas/details/WorkAreaDetailsPageSettings.vue"
          )
      },
      {
        path: "employees",
        name: "workAreaEmployees",
        meta: { page: "workAreas" },
        component: () =>
          import(
            /* webpackChunkName: "workAreaEmployees" */ "@/features/workAreas/details/WorkAreaDetailsPageEmployees.vue"
          )
      },
      {
        path: "demand",
        name: "workAreaDemand",
        meta: { page: "workAreas" },
        component: () =>
          import(/* webpackChunkName: "workAreaDemand" */ "@/features/workAreas/details/WorkAreaDetailsPageDemand.vue")
      },
      {
        path: "demand/:demandTemplateId",
        name: "demandTemplateDetails",
        meta: { page: "workAreas" },
        component: () =>
          import(
            /* webpackChunkName: "demandTemplateDetails" */ "@/features/demandTemplates/details/DemandTemplateDetailsPage.vue"
          )
      }
    ],
    meta: {
      requiresAuth: true,
      page: "workAreas"
    }
  }
];

export default workAreaRoutes;
