<template>
  <div class="flex flex-col space-y-2 overflow-hidden py-3 items-center justify-end sm:py-2 sm:items-end sm:justify-start">
    <transition-group
      name="notification-list"
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="flex flex-col w-screen px-4 items-center sm:items-end sm:justify-start justify-end"
        v-for="notification in notifications"
        :key="notification.id"
      >
        <BaseNotification :notification="notification" />
      </div>
    </transition-group>
  </div>
</template>

<script>
// Component is required due to the issues that notifications would not close after the slide over is closed
import { defineComponent } from "vue";
import useNotifications from "./useNotifications";
import BaseNotification from "./BaseNotification.vue";

export default defineComponent({
  name: "NotificationList",
  components: {
    BaseNotification,
  },
  setup() {
    const { notifications } = useNotifications();

    return { notifications };
  },
});
</script>

<style>
</style>