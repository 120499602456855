import { createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "@aws-amplify/auth";
import useAuth from "@/api/auth/useAuth";

// session variable
const { session: sessionVariable, deleteSession } = useAuth();

// api url
let apiUri = null;
if (
  process.env.NODE_ENV === "development" &&
  process.env.VUE_APP_DEBUG_PROD_API !== "true"
) {
  apiUri = process.env.VUE_APP_HASURA_URL_DEV;
} else {
  apiUri = process.env.VUE_APP_HASURA_URL_PROD;
}

// Chaining multiple links, here: request, auth, error
// https://www.apollographql.com/docs/react/api/link/introduction/#composing-a-link-chain

// Request related information
const httpLink = createHttpLink({
  uri: apiUri
});

const getHeaders = (headers: any) => {
  // return header with admin secret in dev mode
  if (
    process.env.NODE_ENV === "development" &&
    process.env.VUE_APP_DEBUG_PROD_API !== "true"
  ) {
    return {
      headers: {
        ...headers,
        "X-Hasura-Admin-Secret": process.env.VUE_APP_HASURA_ADMIN_SECRET
      }
    };
    // cognito authentication flow in production
  } else {
    let token = "";
    // check jwt token before every request
    return (
      // currentSession() function automatically refreshes id token, if refresh token is not expired
      Auth.currentSession()
        .then(session => {
          token = session.getIdToken().getJwtToken();
          // return the headers to the context so httpLink can read them
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
              "X-Hasura-User-Id": sessionVariable.value?.userId,
              "X-Hasura-Tenant-Id": sessionVariable.value?.tenantId,
              "X-Hasura-Role": sessionVariable.value?.tenantId
            }
          };
        })
        // invalidate session, if refresh token is invalid
        .catch(() => {
          console.log("Session expired");
          alert("Session expired. Please login again.");
          deleteSession();
        })
    );
  }
};

// Authentication related information
// this function is executed with each grapqhl request
const authLink = setContext((_, { headers }) => {
  // check jwt token before every request
  return getHeaders(headers);
});

// Error related information
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError)
    console.log(`[Network error]: ${networkError}`, networkError);
});

// export concatinated link
export default authLink.concat(errorLink).concat(httpLink);
