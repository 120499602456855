/**
 * Click outside element
 */

// eslint-disable-next-line
let handleClickOutside: any = {};

// recursivly find parent nodes and add to list
// id: id of "maximum" node, e.g. "app"
// eslint-disable-next-line
function findUpTag(el: any, id: string) {
  const idList = [];
  while (el.parentNode) {
    el = el.parentNode;
    if (el.id) {
      idList.push(el.id);
    }
    if (el.id === id) {
      return idList;
    }
  }
  return idList;
}

export function addDirectiveClickOutside(app: any) {
  app.directive("click-outside", {
    // When the bound element is mounted into the DOM...
    mounted(el: any, binding: any) {
      // Focus the element
      handleClickOutside = (e: MouseEvent) => {
        e.stopPropagation();
        // Get the handler method name and the exclude array
        // from the object used in v-click-outside
        const { handler, excludedIds } = binding.value;
        // add default ids to exclude
        const excludeList = [...excludedIds, "base-notification", "base-modal"];
        // Create list of all ids of child elements
        const target = e.target as HTMLTextAreaElement;
        const pathIdList = findUpTag(target, "app");
        pathIdList.unshift(target.id); // add target id

        // Check if any excluded id is in component tree
        for (let i = 0; i < excludeList.length; i++) {
          if (pathIdList.includes(excludeList[i])) {
            return false;
          }
        }
        // Call close funcion, if no element was found
        handler();
      };
      // start listening for clicks
      document.addEventListener("mousedown", handleClickOutside);
    },
    unmounted() {
      // stop listening for clicks
      document.removeEventListener("mousedown", handleClickOutside);
    }
  });
}
