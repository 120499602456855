import { InMemoryCache } from "@apollo/client";

// cache
const cache = new InMemoryCache({
  typePolicies: {
    // custom merge function for demand template
    demand_template: {
      fields: {
        demand_parents: { merge: false },
        recurrence_patterns: { merge: false }
      }
    },
    recurrence_pattern: {
      fields: {
        byweekday: { merge: false }
      }
    },
    schedule: {
      fields: {
        shifts: { merge: false }
      }
    },

    // query policies for top level queries
    Query: {
      fields: {
        users: {
          // Short for always preferring incoming over existing data.
          merge: false
        },
        employee_work_area: {
          // Short for always preferring incoming over existing data.
          merge: false
        },
        work_area: {
          // Short for always preferring incoming over existing data.
          merge: false
        },
        demand_template: {
          // Short for always preferring incoming over existing data.
          merge: false
        },
        schedule: {
          // Short for always preferring incoming over existing data.
          merge: false
        }
      }
    }
  }
});

export default cache;
