
import { defineComponent } from "vue";
import NavbarTopProfileDropdown from "./NavbarTopProfileDropdown.vue";

export default defineComponent({
  name: "NavbarTop",
  components: { NavbarTopProfileDropdown },
  emits: ["togglesidebar"],
  setup(props, context) {
    // expand sidebar
    const onToggleSidebar = () => {
      context.emit("togglesidebar");
    };

    return { onToggleSidebar };
  }
});
