// config for aws amplify
// https://serverless-stack.com/chapters/configure-aws-amplify.html

export const config = {
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_KE7B0CaC7",
    APP_CLIENT_ID: "o59djhjg06uo7hb2chqrf1vo8"
  }
};
