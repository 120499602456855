
import { defineComponent, ref } from "vue";
import NavbarTop from "./components/navbar/NavbarTop.vue";
import NavbarSide from "./components/navbar/NavbarSide.vue";
import NotificationList from "@/components/notifications/NotificationList.vue";
import useAuth from "@/api/auth/useAuth";

export default defineComponent({
  name: "App",
  components: {
    NavbarTop,
    NavbarSide,
    NotificationList
  },
  setup() {
    const sidebarVisible = ref(false);

    const onToggleNavbarSide = () => {
      sidebarVisible.value = !sidebarVisible.value;
    };

    // session variable
    const { session } = useAuth();

    return {
      sidebarVisible,
      onToggleNavbarSide,
      session
    };
  }
});
