import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import addRouteProtection from "./routeProtection";
import workAreaRoutes from "./routes/workAreaRoutes";
import employeeRoutes from "./routes/employeeRoutes";
import scheduleRoutes from "./routes/scheduleRoutes";

const baseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/test",
    // component: () => import(/* webpackChunkName: "scheduleList" */ '@/pages/ScheduleListPage.vue')
    component: () => import(/* webpackChunkName: "test" */ "@/pages/TestPage.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    components: {
      auth: () => import(/* webpackChunkName: "login" */ "@/pages/LoginPage.vue")
    }
  },

  {
    path: "/test",
    name: "test",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "test" */ "@/pages/TestPage.vue"),
    meta: {
      requiresAuth: true
    }
  }
];

const routes = baseRoutes.concat(employeeRoutes, workAreaRoutes, scheduleRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

addRouteProtection(router, routes);

export default router;
