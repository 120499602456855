import { RouteRecordRaw } from "vue-router";

const employeeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/employees",
    name: "employees",
    component: () => import(/* webpackChunkName: "employees" */ "@/pages/EmployeesPage.vue"),
    meta: {
      requiresAuth: true,
      page: "employees"
    }
  },

  {
    path: "/employees/:employeeId",
    name: "employeeDetails",
    redirect: "/employees/:employeeId/profile",
    component: () => import(/* webpackChunkName: "employeeDetails" */ "@/pages/EmployeeDetailsPage.vue"),
    children: [
      {
        path: "profile",
        name: "employeeProfile",
        meta: { page: "employees" },
        component: () =>
          import(
            /* webpackChunkName: "employeeProfile" */ "@/features/employees/details/EmployeeDetailsPageProfile.vue"
          )
      },
      {
        path: "absences",
        name: "employeeAbsences",
        meta: { page: "employees" },
        component: () =>
          import(
            /* webpackChunkName: "employeeAbsences" */ "@/features/employees/details/EmployeeDetailsPageAbsences.vue"
          )
      },
      {
        path: "work-areas",
        name: "employeeWorkAreas",
        meta: { page: "employees" },
        component: () =>
          import(
            /* webpackChunkName: "employeeWorkAreas" */ "@/features/employees/details/EmployeeDetailsPageWorkAreas.vue"
          )
      }
    ],
    meta: {
      requiresAuth: true,
      page: "employees"
    }
  }
];

export default employeeRoutes;
