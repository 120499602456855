// Amplify authentication client
import { Amplify } from "aws-amplify";
import { config } from "../amplifyConfig";

export const authClient = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    }
  });
};
