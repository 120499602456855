// functions related to date actions
import {
  format,
  parseISO,
  parse,
  add,
  sub,
  isBefore,
  isAfter,
  isSameMonth,
  isSameWeek,
  isSameDay,
  isValid,
  eachDayOfInterval,
  eachWeekOfInterval,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  addDays,
  getHours,
  getMinutes,
  getTime,
  differenceInMinutes,
  differenceInHours,
  set
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

// constants depending on localization
export const WEEK_STARTS_ON = 1;
export const DATE_FORMAT = "dd.MM.yyyy";
export const TIME_FORMAT = "HH:mm";

// // time type
// // https://stackoverflow.com/questions/51445767/how-to-define-a-regex-matched-string-type-in-typescript
// type HourPrefix = '0'|'1'|'2';
// type MinutePrefix = HourPrefix | '3'|'4'|'5';
// type Digit = MinutePrefix |'6'|'7'|'8'|'9';
// type Time = `${HourPrefix | ''}${Digit}:${MinutePrefix}${Digit}`;
// const validTimes: Time[] = ["00:00","01:30", "23:59", "16:30"]
// const invalidTimes: Time[] = ["30:00", "23:60", "0:61"] // all emit error

// Convert ISO String to date
function parseDateIso(date: string) {
  return parseISO(date);
}

// convert iso string to date
function convertDate(date: string | Date) {
  if (typeof date === "string") {
    date = parseDateIso(date);
  }
  return date;
}

export function checkIsValid(date: string | Date) {
  return isValid(date);
}

// Convert string in given format to date
export function parseDateString(date: Date | string | null, dateFormat = DATE_FORMAT) {
  if (!date) {
    return undefined;
  } else if (typeof date === "string") {
    const parsedDate = parse(date, dateFormat, new Date());
    return parsedDate;
  } else {
    return date;
  }
}

// convert hh:mm:ss to hh:mm
export function removeSecondsFromTimeString(time: string) {
  const splittedString = time.split(":");
  // only keep the values before and after the first colon
  if (splittedString.length > 2) time = splittedString.slice(0, 2).join(":");
  return time;
}

// Convert time string to date
export function parseTimeString(
  time: string,
  date?: Date | string,
  timezone?: "Europe/Berlin",
  timeFormat = TIME_FORMAT
) {
  // initialize date if no date is given
  if (!date) {
    date = new Date();
    // convert date stringt to date
  } else if (typeof date === "string") {
    date = parseDateIso(date);
  }

  // convert hh:mm:ss to hh:mm
  time = removeSecondsFromTimeString(time);

  // date with time of given string
  const newDate = parse(time, timeFormat, date);

  // add timezone
  if (!timezone) {
    return newDate;
  } else return zonedTimeToUtc(newDate, timezone);
}

// create timestamp with 0 as default
export function createTimestamp({
  year = 0,
  month = 0,
  day = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0
}) {
  // const { year, month, day, hours, minutes, seconds, milliseconds } = options;
  return new Date(year, month, day, hours, minutes, seconds, milliseconds);
}

export function formatDate(date: string | Date, dateFormat = DATE_FORMAT) {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }
  // format date based on input string
  return format(date, dateFormat);
}

// get time string from timestamp
export function getTimeStringFromDate(date: string | Date, timeFormat = TIME_FORMAT) {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }
  // format date based on input string
  return format(date, timeFormat);
}

// set date values to given parameters
export function setDate(
  date: string | Date,
  options: {
    year?: number;
    month?: number;
    week?: number;
    date?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  }
) {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }
  // format date based on input string
  return set(date, options);
}

export function addDate(
  date: string | Date,
  options: { years?: number; months?: number; weeks?: number; days?: number; hours?: number; minutes?: number }
) {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }
  // format date based on input string
  return add(date, options);
}

export function subDate(
  date: string | Date,
  options: { years?: number; months?: number; weeks?: number; days?: number; hours?: number; minutes?: number }
) {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }
  // format date based on input string
  return sub(date, options);
}

export function checkSameDate(date1: string | Date, date2: string | Date, type: "day" | "week" | "month") {
  // convert iso string to date
  if (typeof date1 === "string") {
    date1 = parseDateIso(date1);
  }

  if (typeof date2 === "string") {
    date2 = parseDateIso(date2);
  }

  switch (type) {
    case "day":
      return isSameDay(date1, date2);
    case "week":
      return isSameWeek(date1, date2);
    case "month":
      return isSameMonth(date1, date2);
  }
}

export function getFirstDay(date: string | Date, type: "week" | "month") {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }

  switch (type) {
    case "week":
      return startOfWeek(date, { weekStartsOn: WEEK_STARTS_ON });
    case "month":
      return startOfMonth(date);
  }
}

export function getLastDay(date: string | Date, type: "week" | "month") {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }

  switch (type) {
    case "week":
      return endOfWeek(date, { weekStartsOn: WEEK_STARTS_ON });
    case "month":
      return endOfMonth(date);
  }
}

export function getWeekDays(date: string | Date, includeWeekend = true) {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }

  // days until friday or sunday
  const dayCount = includeWeekend ? 6 : 4;
  // get frist day of week
  const weekStart = getFirstDay(date, "week");
  // get all days of week
  const weekDays = eachDayOfInterval({
    start: weekStart,
    end: addDays(weekStart, dayCount)
  });

  return weekDays;
}

// Get start days of each week in the month of the given date
export function getMonthWeeksStartDays(date: string | Date) {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }

  const weekStartDays = eachWeekOfInterval(
    { start: getFirstDay(date, "month"), end: getLastDay(date, "month") },
    { weekStartsOn: WEEK_STARTS_ON }
  );

  // return array of 4 - 6 days
  return weekStartDays;
}

// get time values as integers
// time as millisecends, and minutes and hours
export function getTimeValues(date: string | Date, type: "time" | "hours" | "minutes") {
  // convert iso string to date
  if (typeof date === "string") {
    date = parseDateIso(date);
  }

  switch (type) {
    case "time":
      return getTime(date);
    case "hours":
      return getHours(date);
    case "minutes":
      return getMinutes(date);
  }
}

// Is date1 before/after date2
export function checkBeforeAfter(date1: string | Date, date2: string | Date, type: "before" | "after") {
  // convert iso string to date
  if (typeof date1 === "string") {
    date1 = parseDateIso(date1);
  }

  if (typeof date2 === "string") {
    date2 = parseDateIso(date2);
  }

  switch (type) {
    case "before":
      return isBefore(date1, date2);
    case "after":
      return isAfter(date1, date2);
  }
}

// Check if first time is before or after
export function checkTimeBeforeAfter(time1: string, time2: string, type: "before" | "after") {
  // create dates from time strings and compare dates
  return checkBeforeAfter(parseTimeString(time1), parseTimeString(time2), type);
}

// Get difference between 2 dates in given measure
// either considering actual dates or only the time of the dates
export function getDifference(date1: string | Date, date2: string | Date, measure: "minutes" | "hours") {
  // convert iso string to date
  date1 = convertDate(date1);
  date2 = convertDate(date2);

  switch (measure) {
    case "minutes":
      return Math.abs(differenceInMinutes(date1, date2));
    case "hours":
      return Math.abs(differenceInHours(date1, date2));
  }
}

// Get string of weekday by the weekday number
export function getWeekdayByNumber(weekdayNumber: number, format: "long" | "short") {
  // get first day of week
  const firstDay = getFirstDay(new Date(), "week");
  // add weeknumber (1=Monday)
  const givenDay = addDate(firstDay, { days: weekdayNumber - 1 });
  // return formated day
  if (format === "long") return formatDate(givenDay, "eeee");
  else return formatDate(givenDay, "eeeeee");
}
