import { computed, reactive } from "vue";
import { v4 as uuidv4 } from 'uuid';
import useTimeouts from "@/utils/useTimeouts"


// Timeouts for adding and removing notifications
// One timeout handler for all notifications
const { addTimeout, removeTimeout } = useTimeouts()

// Type of notification state
interface NotificationState {
    notifications: Notification[];
}

// Initialize notification state
const state: NotificationState = reactive({
    notifications: [],
})

export interface Notification {
    id: string;
    type: "info" | "success" | "error";
    title: string;
    timeout: number; // time after which the notification will get removed
    body?: string;
}

export const notificationFactory = (type: "info" | "success" | "error", title: string, body?: string, timeout = 3000) => {
    const notification: Notification = {
        id: uuidv4(),
        type: type,
        title: title,
        timeout: timeout,
        body: body
    }
    return notification
}

// Notification management
export default function useNotifications() {

    // Remove notification
    const removeNotification = (id: string) => {
        // console.log("Remove notification")
        // remove timeout
        removeTimeout(id)
        // remove notification
        state.notifications = [...state.notifications.filter(n => n.id != id)]
    }

    // Add new notification
    // Notification will be removed after a certain amout of time
    const addNotification = (notification: Notification) => {
        // console.log("Add notification")
        // add notification to state
        state.notifications = [notification, ...state.notifications]
        // remove notification from state
        addTimeout(removeNotification, notification.id, notification.timeout)
    }

    return {
        notifications: computed(() => (state.notifications)),
        addNotification,
        removeNotification,
    }
}