<template>
  <div class="flex flex-shrink-0 h-full">
    <div class="flex flex-col transition-all ease-in-out duration-500" :class="[visible ? 'w-56' : 'w-16']">
      <!-- NavbarSide component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow pt-4 pb-4 bg-white">
        <div class="flex items-center ml-3">
          <img class="h-8 w-auto mr-1" src="@/assets/icon_placeholder.svg" alt="Logo Icon" />
          <transition
            enter-active-class="ease-out duration-100"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <span v-if="visible" class="whitespace-nowrap ml-1 text-xl text-gray-900 font-bold"> Kita-Dienstplan</span>
          </transition>
        </div>
        <nav class="flex flex-col mt-5 px-2 bg-white space-y-1 overflow-hidden">
          <NavbarSideLink :link="'/schedules'" :routePage="'schedules'" :showText="visible" v-if="true">
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </template>
            <template v-slot:text>Schedules</template>
          </NavbarSideLink>

          <NavbarSideLink :link="'/employees'" :routePage="'employees'" :showText="visible" v-if="true">
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </template>
            <template v-slot:text>Employees</template>
          </NavbarSideLink>

          <NavbarSideLink :link="'/work-areas'" :routePage="'workAreas'" :showText="visible" v-if="true">
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
              </svg>
            </template>
            <template v-slot:text>Work areas</template>
          </NavbarSideLink>

          <NavbarSideLink :link="'/test'" :routePage="'test'" :showText="visible">
            <template v-slot:icon>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                />
              </svg>
            </template>
            <template v-slot:text>Test</template>
          </NavbarSideLink>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavbarSideLink from "./NavbarSideLink.vue";

export default defineComponent({
  name: "NavbarSide",
  components: {
    NavbarSideLink
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {};
  }
});
</script>
