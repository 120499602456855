import { createApp, h, provide } from "vue";
import App from "./App.vue";
import router from "./router";
// global components
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseLabel from "@/components/base/BaseLabel.vue";
// api
import { DefaultApolloClient } from "@vue/apollo-composable";
import { defaultClient } from "@/api/apolloClient";
// auth
import useAuth from "@/api/auth/useAuth";
import { authClient } from "@/api/auth/authClient";
// time intervals / daily planning period
import useTimeIntervals from "@/utils/useTimeIntervals";
// directives
import { addDirectiveClickOutside } from "@/utils/directives";

// initialize authentication
authClient(); // initialize amplify auth config
const { checkSessonVariable } = useAuth();
checkSessonVariable(); // initialize session from cache and set session variable

// Initialize global planning intervals
// values should be moved to config in database
const { initializeTimeIntervals } = useTimeIntervals();
const startTime = "06:00";
const endDateTime = "18:00";
const timeIntervalSize = 15;
initializeTimeIntervals(startTime, endDateTime, timeIntervalSize);

// add apollo client
const app = createApp({
  setup() {
    provide(DefaultApolloClient, defaultClient);
  },
  render() {
    return h(App);
  }
}).use(router);

// registragion of global components
app.component("BaseButton", BaseButton);
app.component("BaseInput", BaseInput);
app.component("BaseLabel", BaseLabel);

// add directives
addDirectiveClickOutside(app);

app.mount("#app");
