<template>
  <div class="flex justify-between mb-1">
    <label class="block text-sm font-medium text-gray-700" :for="forId">
      <span v-if="text !== ''">{{ text }}</span>
      <slot name="label"></slot>
    </label>
    <div class="text-sm text-gray-500">
      <slot name="hint"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseLabel",
  props: {
    text: {
      type: String,
      default: ""
    },
    forId: {
      type: String,
      default: "baseLabelPlaceholder"
    }
  }
});
</script>
