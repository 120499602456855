/**
 * Global state for time intervals
 * Stores the global start and end time of the planning period and the time interval size
 * Used by: gantt, demand
 */
import { computed, reactive } from "vue";
import { getDifference, addDate, setDate, parseTimeString, getTimeValues } from "@/utils/dateHelpers";

/**
 * Create list of dates in steps based on time interval size
 * @param startDateTime Start date of gantt chart
 * @param endDateTime End date of gantt chart
 * @param timeIntervalSize Length of time interval in minutes
 * @param coverFullHour Create time intervals for full hours
 */
export function createTimeIntervals(
  startDateTime: Date | string,
  endDateTime: Date | string,
  timeIntervalSize: number,
  coverFullHour = false
) {
  // cover full hours
  if (coverFullHour === true) {
    startDateTime = setDate(startDateTime, { minutes: 0 });
    // create full hour for end time. it first has to be checked wheather there is already a full hour
    const minutes = getTimeValues(endDateTime, "minutes");
    if (minutes > 0) {
      // ceil minutes to create full hour coverage
      endDateTime = setDate(endDateTime, { minutes: 59 });
    }
  }

  // calculate number of time intervals
  const minutesStartToEnd = getDifference(endDateTime, startDateTime, "minutes");
  const timeIntervalCount = Math.ceil(minutesStartToEnd / timeIntervalSize);

  // create time intervals
  const timeIntervals = [];
  for (let i = 0; i < timeIntervalCount; i++) {
    const interval = addDate(startDateTime, { minutes: i * timeIntervalSize });
    timeIntervals.push(interval);
  }
  return timeIntervals;
}

// Type of time invtervals state
interface TimeInvervalsState {
  startTime: string;
  endTime: string;
  timeIntervalSize: number;
  timeIntervals: Date[];
}

// Initialize time intercals state
const state: TimeInvervalsState = reactive({
  startTime: "00:00",
  endTime: "00:00",
  timeIntervalSize: 0,
  timeIntervals: []
});

/**
 * Time Intervals State
 */
export default function useTimeIntervals() {
  // initialize state
  const initializeTimeIntervals = (startTime: string, endTime: string, timeIntervalSize: number) => {
    state.startTime = startTime;
    state.endTime = endTime;
    state.timeIntervalSize = timeIntervalSize;
    state.timeIntervals = createTimeIntervals(
      parseTimeString(startTime),
      parseTimeString(endTime),
      timeIntervalSize,
      true
    );
  };

  // create time intervals for a given day
  const createDailyTimeIntervals = (date: Date) => {
    return createTimeIntervals(
      parseTimeString(state.startTime, date),
      parseTimeString(state.endTime, date),
      state.timeIntervalSize,
      true
    );
  };

  // get time interval index of given date
  const getTimeIntervalIndexFromDate = (date: Date) => {
    // difference of first time interval and given date
    const difference = getDifference(parseTimeString(state.startTime, date), date, "minutes");
    // get number of intervals based on difference
    const intervalIndex = Math.floor(difference / state.timeIntervalSize) + 1;
    return intervalIndex;
  };

  return {
    state,
    initializeTimeIntervals,
    createDailyTimeIntervals,
    getTimeIntervalIndexFromDate,
    startTime: computed(() => state.startTime),
    endTime: computed(() => state.endTime),
    timeIntervalSize: computed(() => state.timeIntervalSize),
    timeIntervals: computed(() => state.timeIntervals)
  };
}
