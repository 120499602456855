
import { defineComponent, computed, ref, watch } from "vue";
import BaseSpinner from "./BaseSpinner.vue";

export default defineComponent({
  name: "BaseButton",
  components: {
    BaseSpinner
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "default",
      validator: (prop: string) => ["default", "primary", "secondary", "danger"].includes(prop)
    },
    size: {
      type: String,
      default: "sm",
      validator: (prop: string) => ["xs", "sm", "md", "lg"].includes(prop)
    },
    rounded: {
      type: String,
      default: "md",
      validator: (prop: string) => ["none", "base", "md", "lg", "full"].includes(prop)
    }
  },
  emits: {
    "btn-click": null
  },
  setup(props) {
    // only show loading state after delay
    const showLoadingState = ref(false);
    watch(
      () => props.loading,
      curr => {
        // if loading is false, always set loading state to false
        if (curr === false) showLoadingState.value = false;
        // if loading is true, show loading state after delay
        else {
          setTimeout(() => {
            // check if loading is stll true after delay before showing state
            if (props.loading === true) showLoadingState.value = true;
          }, 500);
        }
      }
    );

    /**
     * Styles
     */

    const baseClasses =
      "inline-flex items-center border shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500";

    const stateClasses = computed(() => {
      return {
        "cursor-not-allowed opacity-50": props.disabled === true || showLoadingState.value === true
      };
    });

    const variantClasses = computed(() => {
      return {
        default: "border-gray-300 text-gray-700 bg-white",
        primary: "border-transparent text-white bg-blue-600",
        secondary: "border-transparent text-white bg-blue-100 text-blue-700",
        danger: "border-transparent text-white bg-red-600"
      }[props.variant as "default" | "primary" | "secondary" | "danger"];
    });

    const variantClassesFeedback = computed(() => {
      return {
        default: "hover:bg-gray-50 active:bg-gray-100",
        primary: "hover:bg-blue-700 active:bg-blue-800",
        secondary: "hover:bg-blue-200 active:bg-blue-300",
        danger: "hover:bg-red-700 active:bg-red-800"
      }[props.variant as "default" | "primary" | "secondary" | "danger"];
    });

    const sizeClasses = computed(() => {
      return {
        xs: "py-1.5 px-2.5 text-xs",
        sm: "py-2 px-4 text-sm",
        md: "py-2 px-4 text-base",
        lg: "py-3 px-6 text-base"
      }[props.size as "xs" | "sm" | "md" | "lg"];
    });

    const roundedClasses = computed(() => {
      return {
        none: "rounded-none",
        base: "rounded",
        md: "rounded-md",
        lg: "rounded-lg",
        full: "rounded-full"
      }[props.rounded as "none" | "base" | "md" | "lg" | "full"];
    });

    return {
      baseClasses,
      stateClasses,
      variantClasses,
      variantClassesFeedback,
      sizeClasses,
      roundedClasses,
      showLoadingState
    };
  }
});
