<template>
  <router-link
    :to="link"
    class="group flex whitespace-nowrap px-2 py-2 rounded-md text-sm leading-5 font-medium focus:outline-none transition ease-in-out duration-150"
    :class="linkClasses"
  >
    <div class="flex w-8 justify-center flex-shrink-0">
      <div
        class="h-6 w-6 flex-shrink-0 group-hover:text-gray-500 transition ease-in-out duration-150"
        :class="iconClasses"
      >
        <slot name="icon"></slot>
      </div>
    </div>
    <transition
      enter-active-class="ease-out duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="showText" class="ml-2">
        <slot name="text"></slot>
      </div>
    </transition>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "NavbarSideLink",
  props: {
    // eslint-disable-next-line
    link: {
      type: String,
      required: true
    },
    showText: {
      default: true,
      type: Boolean
    },
    routeNameList: {
      type: Array
    },
    routePage: {
      type: String
    }
  },
  setup(props) {
    const route = useRoute();

    // Create status for css classes
    // Links if active, if matches either routeNameList or routePage
    const linkStatus = computed(() => {
      if (props.routePage != undefined) {
        if (route.meta.page == props.routePage) {
          return "active";
        } else {
          return "inactive";
        }
      } else if (props.routeNameList != undefined) {
        if (props.routeNameList.includes(route.name)) {
          return "active";
        } else {
          return "inactive";
        }
      }
    });

    // CSS classes for icon
    const iconClasses = computed(() => {
      return {
        active: "text-gray-600  group-focus:text-gray-700",
        inactive: "text-gray-500  group-focus:text-gray-600"
      }[linkStatus.value as "active" | "inactive"];
    });
    // CSS classes for text
    const linkClasses = computed(() => {
      return {
        active: "text-gray-900 bg-gray-100 hover:text-gray-900 hover:bg-gray-100 active:bg-gray-200",
        inactive: "text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:text-gray-900 focus:bg-gray-50"
      }[linkStatus.value as "active" | "inactive"];
    });

    return { iconClasses, linkClasses };
  }
});
</script>
