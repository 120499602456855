<template>
  <Menu>
    <MenuButton
      class="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:text-gray-500"
      id="user-menu"
      aria-label="User menu"
      aria-haspopup="true"
    >
      <svg class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <a
              :class="active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'"
              class="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
            >
              Settings
            </a>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <a
              href="#sign-out"
              :class="active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'"
              class="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
              @click="onLogout"
            >
              Sign out
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { defineComponent } from "vue";
// auth
import { Auth } from "@aws-amplify/auth";
import useAuth from "@/api/auth/useAuth";

export default defineComponent({
  name: "NavbarTopProfileDropdown",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  },
  setup() {
    // session variable
    const { deleteSession } = useAuth();
    // logout
    const onLogout = () => {
      Auth.signOut()
        .then(() => {
          deleteSession();
        })
        .catch(e => {
          console.error(e);
        });
    };

    return { onLogout };
  }
});
</script>
